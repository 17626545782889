<template>
  <div>
    <div class="col-12">
      <c-table
        ref="table1"
        title="판정 결과"
        tableId="table1"
        :columns="grid1.columns"
        :gridHeight="grid1.height"
        :data="grid1.data"
        :columnSetting="false"
        :isFullScreen="false"
        :expandAll="true"
        selection="multiple"
        rowKey="col3"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" icon="add" />
            <c-btn label="제외" icon="remove" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'check-up-disease',
  props: {
    data: {
      type: Object,
      default: () => ({
        checkUpId: '',
        diseaseList: [],
      }),
    },
  },
  data() {
    return {
      editable: false,
      Items1: [
        { code: '1', codeName: '현재조건 작업가능' },
        { code: '2', codeName: '일장조건 작업가능' },
      ],
      Items2: [
        { code: '1', codeName: '필요없음' },
        { code: '2', codeName: '건강상담' },
      ],
      col3Items: [
        { code: 'A', codeName: 'A' },
        { code: 'B', codeName: 'B' },
        { code: 'C', codeName: 'C' },
        { code: 'C1', codeName: 'C1' },
        { code: 'C2', codeName: 'C2' },
        { code: 'CN', codeName: 'CN' },
        { code: 'D1', codeName: 'D1' },
        { code: 'D2', codeName: 'D2' },
        { code: 'DN', codeName: 'DN' },
        { code: 'R', codeName: 'R' },
        { code: 'U', codeName: 'U' },
      ],
      bbItems: [
        { code: 'Y', codeName: '적용' },
        { code: 'N', codeName: '미적용' },
      ],
      grid1: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '판정',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '질환종류',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '질환',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '소견',
            align: 'center',
            sortable: false,
          },
          {
            name: 'col4',
            field: 'col4',
            label: '비고',
            align: 'left',
            sortable: false,
          },
        ],
        height: '330px',
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.getDetail();
    },
    getDetail() {
      this.grid1.data = [
        {
          col1: 'D1', 
          col2: '소화기계의 질환', 
          col3: '식도역류증', 
          col4: '', 
        },
      ]
    },
  }
};
</script>