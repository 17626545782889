var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "c-table",
          {
            ref: "table1",
            attrs: {
              title: "판정 결과",
              tableId: "table1",
              columns: _vm.grid1.columns,
              gridHeight: _vm.grid1.height,
              data: _vm.grid1.data,
              columnSetting: false,
              isFullScreen: false,
              expandAll: true,
              selection: "multiple",
              rowKey: "col3",
            },
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", { attrs: { label: "추가", icon: "add" } }),
                    _c("c-btn", { attrs: { label: "제외", icon: "remove" } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }